import {userServices} from 'services/user';
import {storageUtil} from 'utils/storage';

export const userActionTypes = {
  USER_LOGIN: 'USER_LOGIN',
  AGENT_DETAIL: 'AGENT_DETAIL',

  AGENT_VISIBLE_EXTENSION: 'AGENT_VISIBLE_EXTENSION',
  AGENT_VISIBLE_CHANGE_EXTENSION: 'AGENT_VISIBLE_CHANGE_EXTENSION',
};

const getCurrentUserInfo = () => (dispatch) => {
  userServices.getCurrentUserInfo().then((response) => {
    const user = response.data;
    storageUtil.setActiveUser(user);

    dispatch({
      type: userActionTypes.USER_LOGIN,
      user,
    });
  });
};

const getCurrentAgentInfo = () => (dispatch) => {
  userServices.getCurrentAgentInfo().then((response) => {
    const agent = response.data;
    storageUtil.setActiveAgent(agent);

    dispatch({
      type: userActionTypes.AGENT_DETAIL,
      agent,
    });
  });
};

const shouldVisibleModalExtension = (visible) => (dispatch) => {
  dispatch({
    type: userActionTypes.AGENT_VISIBLE_EXTENSION,
    data: visible,
  });
};

const shouldVisibleChangeExtension = (visible) => (dispatch) => {
  dispatch({
    type: userActionTypes.AGENT_VISIBLE_CHANGE_EXTENSION,
    data: visible,
  });
};

export const userActions = {
  getCurrentUserInfo,
  getCurrentAgentInfo,

  shouldVisibleModalExtension,
  shouldVisibleChangeExtension,
};
