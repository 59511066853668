import vars from 'vars';

const getAccessToken = () => window.TekoID.user.getAccessToken();
const clearAccessToken = () => {
  window.TekoID.user.logout(vars.REACT_APP_REDIRECT_LOGOUT_URI);
};

export const cookieUtil = {
  getAccessToken,
  clearAccessToken,
};
