import Loadable from 'react-loadable';
import Loading from 'containers/fragments/Loading';

const Profile = Loadable({
  loader: () => import('views/User/Profile'),
  loading: Loading,
});

const Guide = Loadable({
  loader: () => import('views/User/Guide'),
  loading: Loading,
});

const OrderDetail = Loadable({
  loader: () => import('views/Order/Detail/OrderDetail'),
  loading: Loading,
});

const CustomerList = Loadable({
  loader: () => import('views/Customer/List/CustomerList'),
  loading: Loading,
});

const CustomerCreate = Loadable({
  loader: () => import('views/Customer/Create/CustomerCreate'),
  loading: Loading,
});

const CustomerDetail = Loadable({
  loader: () => import('views/Customer/Detail/CustomerDetail'),
  loading: Loading,
});

const TicketList = Loadable({
  loader: () => import('views/Ticket/List/TicketList'),
  loading: Loading,
});

const TicketDetail = Loadable({
  loader: () => import('views/Ticket/Detail/TicketDetail'),
  loading: Loading,
});

const CallList = Loadable({
  loader: () => import('views/CallLog/List/CallList'),
  loading: Loading,
});

const DashboardInbound = Loadable({
  loader: () => import('views/DashboardInbound/Dashboard'),
  loading: Loading,
});

const ReportInboundCall = Loadable({
  loader: () => import('views/ReportInbound/Call/ReportCall'),
  loading: Loading,
});
const ReportInboundCallTime = Loadable({
  loader: () => import('views/ReportInbound/Call/ReportCallTime'),
  loading: Loading,
});
const ReportInboundCallPure = Loadable({
  loader: () => import('views/ReportInbound/Call/ReportCallPure'),
  loading: Loading,
});
const ReportInboundCallDay = Loadable({
  loader: () => import('views/ReportInbound/Call/ReportCallDay'),
  loading: Loading,
});

const ReportTicketTypeStatus = Loadable({
  loader: () => import('views/ReportInbound/Ticket/ReportTicketTypeStatus'),
  loading: Loading,
});
const ReportTicketTypeProcess = Loadable({
  loader: () => import('views/ReportInbound/Ticket/ReportTicketTypeProcess'),
  loading: Loading,
});
const ReportTicketTypeChannel = Loadable({
  loader: () => import('views/ReportInbound/Ticket/ReportTicketTypeChannel'),
  loading: Loading,
});
const ReportTicketTypeDay = Loadable({
  loader: () => import('views/ReportInbound/Ticket/ReportTicketTypeDay'),
  loading: Loading,
});

const ReportPersonCall = Loadable({
  loader: () => import('views/ReportPerson/ReportPersonCall'),
  loading: Loading,
});
const ReportPersonTimeline = Loadable({
  loader: () => import('views/ReportPerson/ReportPersonTimeline'),
  loading: Loading,
});

const DepartmentList = Loadable({
  loader: () => import('views/Department/List/DepartmentList'),
  loading: Loading,
});

const DepartmentCreate = Loadable({
  loader: () => import('views/Department/Create/DepartmentCreate'),
  loading: Loading,
});

const DepartmentUpdate = Loadable({
  loader: () => import('views/Department/Update/DepartmentUpdate'),
  loading: Loading,
});

const PersonList = Loadable({
  loader: () => import('views/Person/List/PersonList'),
  loading: Loading,
});

const PersonCreate = Loadable({
  loader: () => import('views/Person/Create/PersonCreate'),
  loading: Loading,
});

const PersonUpdate = Loadable({
  loader: () => import('views/Person/Update/PersonUpdate'),
  loading: Loading,
});

export default {
  home: {
    exact: true,
    path: '/',
    name: 'Trang chủ',
  },
  me: {
    path: '/me',
    name: 'Trang cá nhân',
    component: Profile,
  },
  guide: {
    path: '/guide',
    name: 'Câu hỏi thường gặp',
    component: Guide,
  },

  orderDetail: {
    path: '/order/detail/:id',
    name: 'Chi tiết đơn hàng',
    component: OrderDetail,
  },

  // INBOUND
  dashboardInbound: {
    path: '/dashboard/inbound',
    name: 'Dashboard',
    component: DashboardInbound,
  },

  customerList: {
    path: '/customer/list',
    name: 'Tìm kiếm khách hàng',
    component: CustomerList,
  },
  customerCreate: {
    path: '/customer/create',
    name: 'Thông tin khách hàng',
    component: CustomerCreate,
  },
  customerDetail: {
    path: '/customer/detail/:id',
    name: 'Thông tin khách hàng',
    component: CustomerDetail,
  },
  customerOrderDetail: {
    path: '/customer/order/:id',
    name: 'Thông tin khách hàng',
    component: CustomerDetail,
  },

  ticketList: {
    path: '/ticket/list',
    name: 'Danh sách Ticket',
    component: TicketList,
  },
  ticketDetail: {
    path: '/ticket/detail/:id',
    name: 'Thông tin Ticket',
    component: TicketDetail,
  },

  callList: {
    path: '/call/list',
    name: 'Danh sách cuộc gọi',
    component: CallList,
  },

  reportInboundCall: {
    path: '/report/inbound/call/total',
    name: 'Thống kê cuộc gọi',
    component: ReportInboundCall,
  },
  reportInboundCallTime: {
    path: '/report/inbound/call/time',
    name: 'Thống kê cuộc gọi',
    component: ReportInboundCallTime,
  },
  reportInboundCallPure: {
    path: '/report/inbound/call/pure',
    name: 'Thống kê cuộc gọi',
    component: ReportInboundCallPure,
  },
  reportInboundCallDay: {
    path: '/report/inbound/call/day',
    name: 'Thống kê cuộc gọi',
    component: ReportInboundCallDay,
  },

  reportTicketTypeStatus: {
    path: '/report/inbound/ticket/status',
    name: 'Thống kê Ticket',
    component: ReportTicketTypeStatus,
  },
  reportTicketTypeProcess: {
    path: '/report/inbound/ticket/process',
    name: 'Thống kê Ticket',
    component: ReportTicketTypeProcess,
  },
  reportTicketTypeChannel: {
    path: '/report/inbound/ticket/channel',
    name: 'Thống kê Ticket',
    component: ReportTicketTypeChannel,
  },
  reportTicketTypeDay: {
    path: '/report/inbound/ticket/day',
    name: 'Thống kê Ticket',
    component: ReportTicketTypeDay,
  },

  reportPersonCall: {
    path: '/report/person/call',
    name: 'Thống kê cuộc gọi',
    component: ReportPersonCall,
  },
  reportPersonTimeline: {
    path: '/report/person/timeline',
    name: 'Thống kê thời gian làm việc',
    component: ReportPersonTimeline,
  },

  departmentList: {
    path: '/department/list',
    name: 'Danh sách phòng ban',
    component: DepartmentList,
  },
  departmentCreate: {
    path: '/department/create',
    name: 'Tạo mới phòng ban',
    component: DepartmentCreate,
  },
  departmentUpdate: {
    path: '/department/:id/update',
    name: 'Thông tin phòng ban',
    component: DepartmentUpdate,
  },

  personList: {
    path: '/employee/list',
    name: 'Danh sách nhân viên',
    component: PersonList,
  },
  personCreate: {
    path: '/employee/create',
    name: 'Tạo mới nhân viên',
    component: PersonCreate,
  },
  personUpdate: {
    path: '/employee/:id/update',
    name: 'Thông tin nhân viên',
    component: PersonUpdate,
  },
};
