import {userActionTypes} from 'actions/user';

export default (state = {}, action) => {
  switch (action.type) {
    case userActionTypes.AGENT_DETAIL:
      return action.agent;

    case userActionTypes.AGENT_VISIBLE_EXTENSION: {
      const visible = action.data;
      let shouldVisibleModalExtension = state.shouldVisibleModalExtension || 0;
      shouldVisibleModalExtension = visible ? shouldVisibleModalExtension + 1 : 0;
      return {...state, shouldVisibleModalExtension};
    }
    case userActionTypes.AGENT_VISIBLE_CHANGE_EXTENSION: {
      const visible = action.data;
      let shouldVisibleChangeExtension = state.shouldVisibleChangeExtension || 0;
      shouldVisibleChangeExtension = visible ? shouldVisibleChangeExtension + 1 : 0;
      return {...state, shouldVisibleChangeExtension};
    }

    default:
      return state;
  }
};
