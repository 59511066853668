import routes from 'routes';

export const navigation = {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      resource: 'dashboard_go',
    },
    {
      title: true,
      name: 'Inbound',
      class: 'text-danger-light mt-3 font-size-16',
    },
    {
      name: 'Khách hàng',
      url: '/customer',
      icon: 'icon-user-female',
      resource: 'customer_go',
    },
    {
      name: 'Ticket',
      url: '/ticket',
      icon: 'icon-tag',
      resource: 'ticket_go',
    },
    {
      name: 'Cuộc gọi',
      url: '/call',
      icon: 'icon-call-in',
      resource: 'call_go',
    },
    {
      title: true,
      name: 'Thống kê',
    },
    {
      name: 'Ticket',
      icon: 'icon-speedometer',
      resource: 'inbound_statistic_go',
      children: [
        {
          name: '➥ Trạng thái',
          url: routes.reportTicketTypeStatus.path,
        },
        {
          name: '➥ Thời gian xử lý',
          url: routes.reportTicketTypeProcess.path,
        },
        {
          name: '➥ Kênh',
          url: routes.reportTicketTypeChannel.path,
        },
        {
          name: '➥ Ngày',
          url: routes.reportTicketTypeDay.path,
        },
      ],
    },
    {
      name: 'Cuộc gọi',
      icon: 'icon-speedometer',
      resource: 'inbound_statistic_go',
      children: [
        {
          name: '➥ Số lượng',
          url: routes.reportInboundCall.path,
        },
        {
          name: '➥ Khung thời gian',
          url: routes.reportInboundCallTime.path,
        },
        {
          name: '➥ Không tạo phiếu',
          url: routes.reportInboundCallPure.path,
        },
        {
          name: '➥ Ngày',
          url: routes.reportInboundCallDay.path,
        },
      ],
    },
    {
      name: 'Nhân viên',
      icon: 'icon-speedometer',
      resource: 'inbound_statistic_go',
      children: [
        {
          name: '➥ Cuộc gọi',
          url: routes.reportPersonCall.path,
        },
        {
          name: '➥ Thời gian làm việc',
          url: routes.reportPersonTimeline.path,
        },
      ],
    },
    {
      title: true,
      name: 'HỆ THỐNG',
      class: 'text-danger-light mt-3 font-size-16',
    },
    {
      name: 'Phòng ban',
      url: '/department',
      icon: 'icon-home',
      resource: 'department_go',
    },
    {
      name: 'Nhân viên',
      url: '/employee',
      icon: 'icon-user',
      resource: 'department_go',
    }
  ],
};
