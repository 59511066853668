import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reportServices} from 'services/report';
import {storageUtil} from 'utils/storage';
import {momentUtil} from 'utils/moment';
import {commonConstants} from 'constants/common';
import moment from 'moment';

class UserWorkingTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inboundAvailable: '0',
      inboundUnavailable: '0',
      outboundAvailable: '0',
      outboundUnavailable: '0',
      ticketAvailable: '0',
      ticketUnavailable: '0',
    };

    this.interval = 0;
  }

  componentDidMount() {
    this.getInterval();
    this.reloadData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getInterval = () => {
    this.interval = setInterval(() => {
      this.reloadData();
    }, commonConstants.REFRESH_RATE * 10);
  };

  reloadData = () => {
    const {activeUser} = this.props;
    const request = {
      fromDate: momentUtil.parseClientMomentToServerDate(moment()),
      toDate: momentUtil.parseClientMomentToServerDate(moment()),
    };
    reportServices.reportPersonTimeline(request).then((response) => {
      const dataSource = response.data;
      const workingTime = dataSource.find((item) => item.id === activeUser.id);
      if (workingTime)
        this.setState({
          inboundAvailable: momentUtil.convertSecondToTime(workingTime.inboundAvailable),
          inboundUnavailable: momentUtil.convertSecondToTime(workingTime.inboundUnavailable),
          outboundAvailable: momentUtil.convertSecondToTime(workingTime.outboundAvailable),
          outboundUnavailable: momentUtil.convertSecondToTime(workingTime.outboundUnavailable),
          ticketAvailable: momentUtil.convertSecondToTime(workingTime.ticketUnavailable),
          ticketUnavailable: momentUtil.convertSecondToTime(workingTime.ticketUnavailable),
        });
    });
  };

  render() {
    const {inboundAvailable, inboundUnavailable} = this.state;
    const {outboundAvailable, outboundUnavailable} = this.state;
    const {ticketAvailable, ticketUnavailable} = this.state;

    const isInbound = inboundAvailable !== '0' || inboundUnavailable !== '0';
    const isOutbound = outboundAvailable !== '0' || outboundUnavailable !== '0';
    const isTicket = ticketAvailable !== '0' || ticketUnavailable !== '0';
    return (
      <div className='d-sm-flex align-items-center d-none text-bold'>
        {isInbound && (
          <div className='d-flex flex-column align-items-center px-2 border rounded mr-2'>
            <span>Inbound</span>
            <span className='cursor-pointer'>
              <span className='text-success-dark' title='Online'>
                {inboundAvailable}
              </span>
              <span> - </span>
              <span className='text-danger-dark' title='Offline'>
                {inboundUnavailable}
              </span>
            </span>
          </div>
        )}
        {isOutbound && (
          <div className='d-flex flex-column align-items-center px-2 border rounded mr-2'>
            <span>Outbound</span>
            <span className='cursor-pointer'>
              <span className='text-success-dark' title='Online'>
                {outboundAvailable}
              </span>
              <span> - </span>
              <span className='text-danger-dark' title='Offline'>
                {outboundUnavailable}
              </span>
            </span>
          </div>
        )}
        {isTicket && (
          <div className='d-flex flex-column align-items-center px-2 border rounded mr-2'>
            <span>Ticket</span>
            <span className='cursor-pointer'>
              <span className='text-success-dark' title='Online'>
                {ticketAvailable}
              </span>
              <span> - </span>
              <span className='text-danger-dark' title='Offline'>
                {ticketUnavailable}
              </span>
            </span>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {
    activeUser: storageUtil.getActiveUser(),
  };
}

export default connect(mapStateToProps)(UserWorkingTime);
