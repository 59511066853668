const actionProcess = {
  0: {id: 0, code: 'UNDEFINED', name: '...', icon: 'stop', color: ''},
  1: {id: 1, code: 'CREATE', name: 'Tạo mới', icon: 'plus-circle', color: '#52c41a'},
  2: {id: 2, code: 'UPDATE', name: 'Chỉnh sửa', icon: 'bulb', color: '#1890ff'},
  3: {id: 3, code: 'VIEW', name: 'Xem chi tiết', icon: 'camera', color: '#bac651'},
  4: {id: 4, code: 'ASSIGN', name: 'Phân công xử lý', icon: 'compass', color: '#ec971f'},
  5: {id: 5, code: 'PROCESS', name: 'Cập nhật xử lý', icon: 'idcard', color: '#722ed1'},
  6: {id: 6, code: 'CUSTOMER_CALL', name: 'Khách hàng gọi lên', icon: 'phone', color: ''},
  7: {id: 7, code: 'REOPEN', name: 'Reopen', icon: 'plus-circle', color: '#722ed1'},
  8: {id: 8, code: 'CLOSE', name: 'Đóng', icon: 'close-circle', color: '#9a9a9a'},
  9: {id: 9, code: 'ASSIGN_PROCESS', name: 'Chọn bộ phận phản hồi', icon: 'setting', color: ''},
  10: {id: 10, code: 'PROCESS_NOTE', name: 'Cập nhật ghi chú', icon: 'info-circle', color: ''},
};

const expiredTypes = {
  1: {id: 1, code: 'NORMAL', name: 'Bình thường', color: ''},
  2: {id: 2, code: 'NEARLY_EXPIRED', name: 'Sắp tới hạn', color: 'orange', ground: 'background-expired-green'},
  3: {id: 3, code: 'EXPIRED', name: 'Quá hạn', color: 'red', ground: 'background-expired-orange'},
  4: {id: 4, code: 'EXPIRED_MAX', name: 'Quá hạn', color: 'red', ground: 'background-expired-orangered', disable: 1},
};

const statusTypes = {
  1: {id: 1, code: 'CREATED', name: 'Tạo mới', color: 'green', inputClass: 'input-ant-tag-green'},
  2: {id: 2, code: 'ASSIGNED', name: 'Chờ xử lý', color: 'cyan', inputClass: 'input-ant-tag-cyan'},
  3: {id: 3, code: 'PROCESSING', name: 'Đang xử lý', color: 'geekblue', inputClass: 'input-ant-tag-geekblue'},
  4: {id: 4, code: 'WAIT_FEEDBACK', name: 'Chờ phản hồi', color: 'purple', inputClass: 'input-ant-tag-purple'},
  5: {id: 5, code: 'CLOSED', name: 'Đã xử lý', color: '', inputClass: 'input-ant-tag-aliceblue'},
  6: {id: 6, code: 'SUCCESS', name: 'Thành công', color: '', inputClass: 'input-ant-tag-green'},
};

export const ticketConstants = {
  actionProcess,
  expiredTypes,
  statusTypes,
  totalCallWarning: 1,
};
