import vars from 'vars';

const AUTHENTICATION_URL = vars.REACT_APP_AUTH_URL;
const BASE_URL = vars.REACT_APP_API_URL;
const BLS_URL = vars.REACT_APP_BLS_URL || 'http://logistic.teksvr.com/pwb/van-don';

export const apiConstants = {
  // Campaign
  CAMPAIGN_SEARCH: `${BASE_URL}/api/v1/campaign/search`,
  CAMPAIGN_POST_ALL: `${BASE_URL}/api/v1/campaign/all`,
  CAMPAIGN_UPDATE_STATUS: (campaignId) => `${BASE_URL}/api/v1/campaign/${campaignId}/status`,
  CAMPAIGN_UPDATE_DELETE: (campaignId) => `${BASE_URL}/api/v1/campaign/${campaignId}`,

  CAMPAIGN_CREATE: `${BASE_URL}/api/v1/campaign`,
  CAMPAIGN_UPDATE_INFO: (campaignId) => `${BASE_URL}/api/v1/campaign/${campaignId}`,
  CAMPAIGN_UPDATE_CRITERIA: (campaignId) => `${BASE_URL}/api/v1/criteria/${campaignId}`,
  CAMPAIGN_GET_INFO: (campaignId) => `${BASE_URL}/api/v1/campaign/${campaignId}`,
  CAMPAIGN_GET_CRITERIA: (campaignId) => `${BASE_URL}/api/v1/criteria/${campaignId}`,

  CAMPAIGN_CRITERIA_STATISTIC: `${BASE_URL}/api/v1/criteria/statistic`,
  CAMPAIGN_SALES_ORDER: `${BASE_URL}/api/v1/sales/order`,

  // Assign
  ASSIGN_GET_CAMPAIGN: `${BASE_URL}/api/v1/assign/campaign`,
  ASSIGN_ORDER_POST: (campaignId) => `${BASE_URL}/api/v1/assign/${campaignId}/assign`,
  ASSIGN_START: (campaignId) => `${BASE_URL}/api/v1/assign/${campaignId}/start`,
  ASSIGN_GET: (campaignId) => `${BASE_URL}/api/v1/assign/${campaignId}/call`,
  ASSIGN_GET_CONVERSATION: (assignId) => `${BASE_URL}/api/v1/assign/${assignId}/conversation`,
  ASSIGN_UPDATE: (assignId) => `${BASE_URL}/api/v1/assign/${assignId}/update`,

  // Conversation
  CONVERSATION_GET: (conversationId) => `${BASE_URL}/api/v1/conversation/${conversationId}`,

  // Script
  SCRIPT_GET: (campaignId) => `${BASE_URL}/api/v1/script/${campaignId}`,
  SCRIPT_QUESTION: (questionId) => `${BASE_URL}/api/v1/question/${questionId}`,

  // User
  USER_LOGIN: `${AUTHENTICATION_URL}/web/login/`,
  USER_LOGOUT: `${AUTHENTICATION_URL}/web/logout/`,
  USER_DETAIL: `${BASE_URL}/api/v1/user`,
  USER_PERMISSION: `${BASE_URL}/api/v1/user/permission`,
  USER_EXT_LOGOUT: `${BASE_URL}/api/v1/user/logout`,

  // Agent
  AGENT_DETAIL: (personId) => `${BASE_URL}/api/v1/callcenter/agent/${personId}`,
  AGENT_EXTENSION: (personId) => `${BASE_URL}/api/v1/callcenter/agent/${personId}`,
  FETCH_EXTENSION: `${BASE_URL}/api/v1/callcenter/extension`,

  // Order
  ORDER_GET: (orderId) => `${BASE_URL}/api/v1/order/${orderId}`,
  ORDER_INBOUND_GET: (orderId) => `${BASE_URL}/api/v1/inbound/order/${orderId}`,
  ORDER_BLS: (blsId) => `${BLS_URL}/${blsId}`,

  // Notification
  ORDER_GET_NOTIFY: (personId) => `${BASE_URL}/api/v1/notify/${personId}`,

  // Customer
  CUSTOMER_FETCH: `${BASE_URL}/api/v1/customer`,
  CUSTOMER_GET: (customerId) => `${BASE_URL}/api/v1/customer/${customerId}`,
  CUSTOMER_ORDER_GET: (orderId) => `${BASE_URL}/api/v1/customer/order/${orderId}`,
  CUSTOMER_GET_EXTRA_ORDER: `${BASE_URL}/api/v1/customer/extra/order`,

  CUSTOMER_CREATE: `${BASE_URL}/api/v1/customer`,
  CUSTOMER_UPDATE: (customerId) => `${BASE_URL}/api/v1/customer/${customerId}`,

  CUSTOMER_HISTORY_CALL: `${BASE_URL}/api/v1/customer/history/call`,
  CUSTOMER_HISTORY_ORDER: `${BASE_URL}/api/v1/customer/history/order`,
  CUSTOMER_HISTORY_TICKET: `${BASE_URL}/api/v1/customer/history/ticket`,
  CUSTOMER_HISTORY_CALLOUT: `${BASE_URL}/api/v1/conversation`,

  INBOUND_ORDER_GET: (orderId) => `${BASE_URL}/api/v1/inbound/order/${orderId}`,
  INBOUND_SALEORDER_GET: (saleOrderId) => `${BASE_URL}/api/v1/inbound/sale_order/${saleOrderId}`,
  INBOUND_ORDER_SEARCH: `${BASE_URL}/api/v1/inbound/order/search`,

  // Static
  GET_PROVINCE: `${BASE_URL}/api/v1/province`,
  GET_TICKET_TYPE: `${BASE_URL}/api/v1/ticket_type`,
  GET_RECEIVE_CHANNEL: `${BASE_URL}/api/v1/receive_channel`,
  GET_TICKET_PRIORITY_TYPE: `${BASE_URL}/api/v1/ticket_priority_type`,
  GET_CHANNEL: `${BASE_URL}/api/v1/channel`,
  GET_DEPARTMENT: `${BASE_URL}/api/v1/department`,

  GET_STATUS_PERMISSION: `${BASE_URL}/api/v1/status/permission`,
  GET_STATUS_FLOW: `${BASE_URL}/api/v1/status/0/flow`,

  GET_LINE: `${BASE_URL}/api/v1/callcenter/line`,
  GET_PERSON_CS: `${BASE_URL}/api/v1/person/cs`,
  GET_PERSON: `${BASE_URL}/api/v1/person`,

  GET_ANSWER_STATUS: `${BASE_URL}/api/v1/answer_status`,
  GET_PROCESS: `${BASE_URL}/api/v1/process`,

  // Ticket
  TICKET_SEARCH: `${BASE_URL}/api/v1/ticket/search`,

  TICKET_GET: (ticketId) => `${BASE_URL}/api/v1/ticket/${ticketId}`,
  TICKET_GET_PROCESS: (ticketId) => `${BASE_URL}/api/v1/ticket/${ticketId}/process`,
  TICKET_GET_PERSON: (departmentId) => `${BASE_URL}/api/v1/department/${departmentId}/person`,

  TICKET_CREATE: `${BASE_URL}/api/v1/ticket`,
  TICKET_UPDATE: (ticketId) => `${BASE_URL}/api/v1/ticket/${ticketId}`,
  TICKET_ASSIGN: `${BASE_URL}/api/v1/ticket/assign`,
  TICKET_ASSIGN_PROCESS: `${BASE_URL}/api/v1/ticket/assign/process`,
  TICKET_PROCESS: `${BASE_URL}/api/v1/ticket/process`,
  TICKET_PROCESS_NOTE: `${BASE_URL}/api/v1/ticket/process/note`,

  TICKET_UPLOAD_FILE: `${BASE_URL}/api/v1/storage`,
  TICKET_PREVIEW_FILE: (storageId) => `${BASE_URL}/api/v1/storage/${storageId}`,
  TICKET_DOWNLOAD_FILE: (storageId) => `${BASE_URL}/api/v1/storage/${storageId}/download`,
  TICKET_EXPORT: `${BASE_URL}/api/v1/ticket/export`,

  // Callnote
  CALLNOTE_FETCH: `${BASE_URL}/api/v1/callnote`,
  CALLNOTE_FETCH_OUT: `${BASE_URL}/api/v1/callnote/out`,
  CALLNOTE_EXPORT: `${BASE_URL}/api/v1/callnote/export`,

  CALLNOTE_GET_SYNC: `${BASE_URL}/api/v1/callnote/sync/get`,
  CALLNOTE_GET: (callLogId) => `${BASE_URL}/api/v1/callnote/${callLogId}/log`,
  CALLNOTE_CREATE: `${BASE_URL}/api/v1/callnote`,
  CALLNOTE_UPDATE: (callLogId) => `${BASE_URL}/api/v1/callnote/${callLogId}`,

  CALLNOTE_GET_AUDIO: (callLogId) => `${BASE_URL}/api/v1/callcenter/recording/${callLogId}/download`,

  // Callout
  CALLOUT: `${BASE_URL}/api/v1/callcenter/callout`,
  CALLOUT_STATUS: `${BASE_URL}/api/v1/callcenter/callout/status`,

  // Department
  DEPARTMENT_FETCH: `${BASE_URL}/api/v1/departments/search`,
  DEPARTMENT_GET: (departmentId) => `${BASE_URL}/api/v1/department/${departmentId}`,
  DEPARTMENT_GET_PERSON: (departmentId) => `${BASE_URL}/api/v1/department/${departmentId}/person`,

  DEPARTMENT_CREATE: `${BASE_URL}/api/v1/departments`,
  DEPARTMENT_UPDATE: (departmentId) => `${BASE_URL}/api/v1/department/${departmentId}`,

  // Dashboard
  DASHBOARD_INBOUND_CALLCENTER: `${BASE_URL}/api/v1/callcenter/dashboard`,
  DASHBOARD_INBOUND_TICKET_COUNT: `${BASE_URL}/api/v1/ticket/statistic/count/status`,
  DASHBOARD_INBOUND_TICKET_LIST: `${BASE_URL}/api/v1/ticket/statistic/list/type`,

  // Report
  REPORT_CONVERSATION: (kind) => `${BASE_URL}/api/v1/conversation/statistic/number/${kind}`,
  EXPORT_CONVERSATION: (kind) => `${BASE_URL}/api/v1/conversation/statistic/number/${kind}/export`,

  REPORT_CALLCENTER: `${BASE_URL}/api/v1/callcenter/report`,
  EXPORT_CALLCENTER: `${BASE_URL}/api/v1/callcenter/report/export`,
  REPORT_CALLNOTE_DAY: `${BASE_URL}/api/v1/callnote/statistic/day`,
  EXPORT_CALLNOTE_DAY: `${BASE_URL}/api/v1/callnote/statistic/day/export`,
  REPORT_CALLNOTE_PURE: `${BASE_URL}/api/v1/callnote/statistic/not_ticket/day`,
  EXPORT_CALLNOTE_PURE: `${BASE_URL}/api/v1/callnote/statistic/not_ticket/day/export`,
  REPORT_CALL_PERIOD: `${BASE_URL}/api/v1/callnote/statistic/call_status/time`,
  EXPORT_CALL_PERIOD: `${BASE_URL}/api/v1/callnote/statistic/call_status/time/export`,

  REPORT_TICKET_TYPE_CHANNEL: (kind) => `${BASE_URL}/api/v1/ticket/statistic/type/${kind}`,
  EXPORT_TICKET_TYPE_CHANNEL: (kind) => `${BASE_URL}/api/v1/ticket/statistic/type/${kind}/export`,
  REPORT_TICKET_BY_DAY: (kind) => `${BASE_URL}/api/v1/ticket/statistic/${kind}/day`,
  EXPORT_TICKET_BY_DAY: (kind) => `${BASE_URL}/api/v1/ticket/statistic/${kind}/day/export`,
  REPORT_TICKET_TYPE_PROCESS: `${BASE_URL}/api/v1/ticket/statistic/process/time`,
  EXPORT_TICKET_TYPE_PROCESS: `${BASE_URL}/api/v1/ticket/statistic/process/time/export`,
  REPORT_TICKET_TYPE_STATUS: (kind) => `${BASE_URL}/api/v1/ticket/statistic/type/${kind}`,
  EXPORT_TICKET_TYPE_STATUS: (kind) => `${BASE_URL}/api/v1/ticket/statistic/type/${kind}/export`,

  REPORT_CALLNOTE_PERSON: `${BASE_URL}/api/v1/callnote/statistic/person`,
  EXPORT_CALLNOTE_PERSON: `${BASE_URL}/api/v1/callnote/statistic/person/export`,
  REPORT_PERSON_TIMELINE: `${BASE_URL}/api/v1/callcenter/statistic/agent/time`,
  EXPORT_PERSON_TIMELINE: `${BASE_URL}/api/v1/callcenter/statistic/agent/time/export`,

  // Response group
  RESPONSE_GROUP_GET_ALL: `${BASE_URL}/api/v1/response/all`,
  RESPONSE_GROUP_GET: (responseId) => `${BASE_URL}/api/v1/response/${responseId}`,
  RESPONSE_GROUP_CREATE: `${BASE_URL}/api/v1/response`,
  RESPONSE_GROUP_DELETE: `${BASE_URL}/api/v1/response`,

  RESPONSE_GROUP_CRITERIA_GET_ALL: `${BASE_URL}/api/v1/response/criteria`,
  RESPONSE_GROUP_CRITERIA_GET_ACTIVE: (responseId) => `${BASE_URL}/api/v1/response/${responseId}/criteria`,
  RESPONSE_GROUP_CRITERIA_UPDATE: (responseId) => `${BASE_URL}/api/v1/response/${responseId}/criteria`,
  RESPONSE_GROUP_CRITERIA_ANSWERS_GET: (criteriaId) => `${BASE_URL}/api/v1/response/criteria/${criteriaId}`,

  RESPONSE_GROUP_ANSWER_GET: (responseId) => `${BASE_URL}/api/v1/response/${responseId}/answer`,
  RESPONSE_GROUP_ANSWER_CREATE: (responseId) => `${BASE_URL}/api/v1/response/${responseId}/answer`,
  RESPONSE_GROUP_ANSWER_UPDATE: (responseId) => `${BASE_URL}/api/v1/response/${responseId}/answer/update`,
  RESPONSE_GROUP_ANSWER_DELETE: (responseId) => `${BASE_URL}/api/v1/response/${responseId}/answer`,

  // Person
  PERSON_GET_ID: (id) => `${BASE_URL}/api/v1/person/${id}`,
  PERSON_GET: `${BASE_URL}/api/v1/person/department`,
  PERSON_GET_SSO: `${BASE_URL}/api/v1/person/get`,
  PERSON_CREATE: `${BASE_URL}/api/v1/person/department`,
  PERSON_UPDATE: `${BASE_URL}/api/v1/person/department`,
};
