import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from 'store';
import {Provider} from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import vars from 'vars';
import App from 'App';
import 'teko-oauth2';

window.TekoID.init({
  clientId: vars.REACT_APP_CLIENT_ID,
  scopes: vars.REACT_APP_SCOPES,
  oauthDomain: vars.REACT_APP_AUTH_URL,
  silent: true,
}).then(() => {
  ReactDOM.render(
    <Provider store={configureStore()}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
});

serviceWorker.unregister();
