import lodash from 'lodash';

const removeNullOrEmpty = (target) => {
  if (target === undefined || target === null) {
    return target;
  }
  if (lodash.isObject(target) && !lodash.isArray(target)) {
    const ret = {};
    Object.keys(target).forEach((key) => {
      const value = target[key];
      if (lodash.isString(value) && lodash.isEmpty(value.trim())) {
        // continue
      } else if (value !== undefined && value !== null) {
        ret[key] = target[key];
      }
    });
    return ret;
  }
  return target;
};

const camelCaseConverter = (target) => {
  if (!lodash.isObject(target)) {
    return target;
  }
  if (lodash.isArray(target)) {
    return target.map((v) => camelCaseConverter(v));
  }
  return lodash.reduce(
    target,
    (r, v, k) => ({
      ...r,
      [lodash.camelCase(k)]: camelCaseConverter(v),
    }),
    {},
  );
};

const snakeCaseConverter = (target) => {
  if (!lodash.isObject(target)) {
    return target;
  }
  if (lodash.isArray(target)) {
    return target.map((v) => snakeCaseConverter(v));
  }
  return removeNullOrEmpty(
    lodash.reduce(
      target,
      (r, v, k) => ({
        ...r,
        [lodash.snakeCase(k)]: snakeCaseConverter(v),
      }),
      {},
    ),
  );
};

const getMoneyFormat = (value) => {
  if (!value) return 0;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const moneyParser = {
  formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
  parser: (value) => value.replace(/-\s?|(\.*)/g, ''),
};

export const converter = {
  camelCaseConverter,
  snakeCaseConverter,

  getMoneyFormat,
  moneyParser,
};
