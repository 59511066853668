import {notification} from 'antd';

const success = (message) => {
  notification.success({message});
  return true;
};

const error = (message) => {
  notification.error({message});
  return false;
};

export const alertUtil = {
  success,
  error,
};
