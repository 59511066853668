import {combineReducers} from 'redux';
import callingStatus from './callingStatus';
import pages from './pages';
import staticData from './staticData';
import userAgent from './userAgent';
import userCurrent from './userCurrent';

export const rootReducer = combineReducers({
  callingStatus,
  pages,
  staticData,
  userAgent,
  userCurrent,
});
