import React, {Component, Fragment} from 'react';

class DefaultFooter extends Component {
  render() {
    return (
      <Fragment>
        <span className='text-bold'>
          <a href='https://teko.vn'>Teko Customer Service</a> &copy; 2018 dev team
        </span>
      </Fragment>
    );
  }
}

export default DefaultFooter;
