import {staticServices} from 'services/static';
import {storageUtil} from 'utils/storage';
import {ticketConstants} from 'constants/ticket';
import lodash from 'lodash';

export const staticActionTypes = {
  GET_PROVINCE: 'GET_PROVINCE',
  GET_TICKET_TYPE: 'GET_TICKET_TYPE',
  GET_NOTE_TYPE: 'GET_NOTE_TYPE',
  GET_LINE: 'GET_LINE',
  GET_RECEIVE_CHANNEL: 'GET_RECEIVE_CHANNEL',
  GET_TICKET_PRIORITY_TYPE: 'GET_TICKET_PRIORITY_TYPE',
  GET_CHANNEL: 'GET_CHANNEL',
  GET_BRANCH: 'GET_BRANCH',
  GET_DEPARTMENT: 'GET_DEPARTMENT',
  GET_STATUS_FLOW: 'GET_STATUS_FLOW',
  GET_LINE_NUMBER: 'GET_LINE_NUMBER',
  GET_AS_PERSON: 'GET_AS_PERSON',
  GET_CS_PERSON: 'GET_CS_PERSON',
  GET_ANSWER_STATUS: 'GET_ANSWER_STATUS',
  GET_PROCESS: 'GET_PROCESS',
};

const getStaticData = () => (dispatch) => {
  staticServices.getProvinces().then((response) => {
    const provinces = lodash.keyBy(response.data, 'id');
    dispatch({
      type: staticActionTypes.GET_PROVINCE,
      data: provinces,
    });
  });

  staticServices.getTicketTypes().then((response) => {
    const ticketTypes = response.data.map((item, index) => ({...item, index}));
    dispatch({
      type: staticActionTypes.GET_TICKET_TYPE,
      data: lodash.keyBy(ticketTypes.filter((type) => type.isTicket), 'index'),
    });
    dispatch({
      type: staticActionTypes.GET_NOTE_TYPE,
      data: lodash.keyBy(ticketTypes.filter((type) => type.isNote), 'index'),
    });
    const lines = {};
    ticketTypes.forEach((type) => {
      if (type.line) lines[type.line] = {typeId: type.id};
    });
    dispatch({
      type: staticActionTypes.GET_LINE,
      data: lines,
    });
  });

  staticServices.getReceiveChannels().then((response) => {
    const receiveChannels = lodash.keyBy(response.data, 'id');
    dispatch({
      type: staticActionTypes.GET_RECEIVE_CHANNEL,
      data: receiveChannels,
    });
  });

  staticServices.getTicketPriorityTypes().then((response) => {
    const ticketPriorityTypes = lodash.keyBy(response.data, 'id');
    dispatch({
      type: staticActionTypes.GET_TICKET_PRIORITY_TYPE,
      data: ticketPriorityTypes,
    });
  });

  staticServices.getChannels().then((response) => {
    const channels = lodash.keyBy(response.data, 'id');
    dispatch({
      type: staticActionTypes.GET_CHANNEL,
      data: channels,
    });
  });

  staticServices.getBranches().then((response) => {
    const departments = response.data;
    dispatch({
      type: staticActionTypes.GET_DEPARTMENT,
      data: lodash.keyBy(departments, 'id'),
    });
    dispatch({
      type: staticActionTypes.GET_BRANCH,
      data: lodash.keyBy(departments.filter((item) => item.isBranch), 'id'),
    });
  });

  staticServices.getStatusPermission().then((response) => {
    const statusPermission = response.data;
    storageUtil.setStatusPermission(statusPermission);
  });

  staticServices.getStatusFlows().then((response) => {
    const statusFlows = {};
    Object.values(ticketConstants.statusTypes).forEach((item) => {
      statusFlows[item.id] = [];
    });
    response.data.forEach((item) => {
      if (!statusFlows[item.status]) statusFlows[item.status] = [];
      statusFlows[item.status].push(item.nextStatus);
    });
    dispatch({
      type: staticActionTypes.GET_STATUS_FLOW,
      data: statusFlows,
    });
  });

  staticServices.getLine().then((response) => {
    const lines = lodash.keyBy(response.data, 'lineNumber');
    dispatch({
      type: staticActionTypes.GET_LINE_NUMBER,
      data: lines,
    });
  });

  staticServices.getAsPersons().then((response) => {
    let asPersons = response.data.map((item, index) => ({...item, index}));
    let csPersons = asPersons.filter((item) => item.isCs);

    asPersons = lodash.keyBy(asPersons, 'index');
    dispatch({
      type: staticActionTypes.GET_AS_PERSON,
      data: asPersons,
    });

    csPersons = lodash.keyBy(csPersons, 'index');
    dispatch({
      type: staticActionTypes.GET_CS_PERSON,
      data: csPersons,
    });
  });

  // staticServices.getAnswerStatus().then((response) => {
  //   const answerStatus = lodash.keyBy(response.data, 'id');
  //   dispatch({
  //     type: staticActionTypes.GET_ANSWER_STATUS,
  //     data: answerStatus,
  //   });
  // });

  // staticServices.getProcess().then((response) => {
  //   const process = lodash.keyBy(response.data, 'id');
  //   dispatch({
  //     type: staticActionTypes.GET_PROCESS,
  //     data: process,
  //   });
  // });
};

export const staticActions = {
  getStaticData,
};
