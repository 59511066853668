import axios from 'axios/index';
import {apiConstants} from 'constants/api';
import {cookieUtil} from 'utils/cookie';
import {storageUtil} from 'utils/storage';
import {authorizationHeader} from 'utils/request';
import {converter} from 'utils/converter';

const logoutExtension = () =>
  axios.get(apiConstants.USER_EXT_LOGOUT, {
    headers: authorizationHeader(),
  });

const logout = async () => {
  await logoutExtension();
  await storageUtil.clearCallingStatus();
  cookieUtil.clearAccessToken();
};

const getCurrentUserInfo = () =>
  axios.get(apiConstants.USER_DETAIL, {
    headers: authorizationHeader(),
  });

const getCurrentUserPermission = () =>
  axios.get(apiConstants.USER_PERMISSION, {
    headers: authorizationHeader(),
  });

const getCurrentAgentInfo = () => {
  const activeUser = storageUtil.getActiveUser();
  return axios.get(apiConstants.AGENT_DETAIL(activeUser.id), {
    headers: authorizationHeader(),
  });
};

const fetchExtension = () =>
  axios.get(apiConstants.FETCH_EXTENSION, {
    headers: authorizationHeader(),
  });

const changeStatusExtension = (request) => {
  const body = converter.snakeCaseConverter(request);
  const activeUser = storageUtil.getActiveUser();
  return axios.put(apiConstants.AGENT_DETAIL(activeUser.id), body, {
    headers: authorizationHeader(),
  });
};

export const userServices = {
  logoutExtension,
  logout,
  getCurrentUserInfo,
  getCurrentUserPermission,
  getCurrentAgentInfo,

  fetchExtension,
  changeStatusExtension,
};
