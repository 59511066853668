const customerTypes = {
  PERSONAL: 1,
  COMPANY: 2,
  GCAFE: 3,
};

const memberLevels = {
  NORMAL: 1,
  GOLD: 2,
  DIAMOND: 3,
};

const memberCardStatus = {
  CANCELED: 1,
  DISABLED: 2,
  ACTIVE: 3,
};

export const customerConstants = {
  customerTypes,
  memberLevels,
  memberCardStatus,
};
