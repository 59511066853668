export const pageActionTypes = {
  CALL_LIST_SAVE: 'CALL_LIST_SAVE',
  TICKET_LIST_SAVE: 'TICKET_LIST_SAVE',
  CUSTOMER_LIST_SAVE: 'CUSTOMER_LIST_SAVE',
  CAMPAIGN_LIST_SAVE: 'CAMPAIGN_LIST_SAVE',
  RESPONSE_LIST_SAVE: 'RESPONSE_LIST_SAVE',
  SURVEY_LIST_SAVE: 'SURVEY_LIST_SAVE',
  SURVEY_CALL_SAVE: 'SURVEY_CALL_SAVE',
  DEPARTMENT_LIST_SAVE: 'DEPARTMENT_LIST_SAVE',
};

const saveCallList = (data) => (dispatch) => {
  dispatch({
    type: pageActionTypes.CALL_LIST_SAVE,
    data,
  });
};

const saveTicketList = (data) => (dispatch) => {
  dispatch({
    type: pageActionTypes.TICKET_LIST_SAVE,
    data,
  });
};

const saveCustomerList = (data) => (dispatch) => {
  dispatch({
    type: pageActionTypes.CUSTOMER_LIST_SAVE,
    data,
  });
};

const saveCampaignList = (data) => (dispatch) => {
  dispatch({
    type: pageActionTypes.CAMPAIGN_LIST_SAVE,
    data,
  });
};

const saveResponseList = (data) => (dispatch) => {
  dispatch({
    type: pageActionTypes.RESPONSE_LIST_SAVE,
    data,
  });
};

const saveSurveyList = (data) => (dispatch) => {
  dispatch({
    type: pageActionTypes.SURVEY_LIST_SAVE,
    data,
  });
};

const saveSurveyCall = (data) => (dispatch) => {
  dispatch({
    type: pageActionTypes.SURVEY_CALL_SAVE,
    data,
  });
};

const saveDepartmentList = (data) => (dispatch) => {
  dispatch({
    type: pageActionTypes.DEPARTMENT_LIST_SAVE,
    data,
  });
};

export const pageActions = {
  saveCallList,
  saveTicketList,
  saveCustomerList,
  saveCampaignList,
  saveResponseList,
  saveSurveyList,
  saveSurveyCall,
  saveDepartmentList,
};
