import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AppBreadcrumb, AppHeader, AppFooter, AppSidebar} from '@coreui/react';
import {Container} from 'reactstrap';
import queryParser from 'query-string';
import routes from 'routes';
import withDocument from 'containers/hocs/routeDocument';
import PopupCalling from 'containers/fragments/PopupCalling';
import DefaultHeader from './DefaultHeader';
import DefaultFooter from './DefaultFooter';
import DefaultSidebar from './DefaultSidebar';

class DefaultLayout extends React.Component {
  render() {
    return (
      <div className='app'>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className='app-body'>
          <AppSidebar fixed display='lg'>
            <DefaultSidebar {...this.props} />
          </AppSidebar>
          <main className='main'>
            <div className='header-divider' />
            <AppBreadcrumb appRoutes={Object.values(routes)} />
            <PopupCalling />
            <Container fluid>
              <Switch>
                {Object.values(routes)
                  .filter((route) => route.component)
                  .map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => {
                        const Component = withDocument(route.component);
                        return (
                          <div className='animated fadeIn'>
                            <Component
                              title={route.name}
                              {...props}
                              {...this.props}
                              params={props.match.params}
                              query={queryParser.parse(props.location.search)}
                            />
                          </div>
                        );
                      }}
                    />
                  ))}
                <Redirect from='/customer' to={routes.customerList.path} />
                <Redirect from='/ticket' to={routes.ticketList.path} />
                <Redirect from='/call' to={routes.callList.path} />

                <Redirect from='/department' to={routes.departmentList.path} />
                <Redirect from='/employee' to={routes.personList.path} />
                <Redirect from='/' to={routes.dashboardInbound.path} />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
