import React from 'react';
import {Container, Row, Col, Button, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';

export default () => (
  <div className='app flex-row align-items-center'>
    <Container>
      <Row className='justify-content-center'>
        <Col md='6'>
          <div className='clearfix'>
            <h1 className='float-left display-3 mr-4'>403</h1>
            <h4 className='pt-3'>Oops, access denied!</h4>
            <p className='text-muted float-left'>You do not have permission to access this page.</p>
          </div>
          <InputGroup className='input-prepend'>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i className='fa fa-search' />
              </InputGroupText>
            </InputGroupAddon>
            <Input size='16' type='text' placeholder='What are you looking for?' />
            <InputGroupAddon addonType='append'>
              <Button color='info'>Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  </div>
);
