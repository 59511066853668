/**
 * @author AnhDV
 * @description Client of Pusher service TEKO.
 */
import io from 'socket.io-client';
import vars from 'vars';

const APP_KEY_PUSHER = vars.REACT_APP_KEY_PUSHER;

class Pusher {
  constructor(key, args = {}) {
    this.key = key;
    this.url = vars.REACT_APP_PUSHER_TEKO_URL;
    this.io = io.connect(this.url, {
      transports: ['websocket'],
      query: {
        key: this.key,
        ...args,
      },
    });
  }

  bind(event, callback) {
    if (!event || event.trim().length === 0) {
      throw new Error('Event can not be empty');
    }

    return this.io.on(event, callback);
  }
}

export const channel = new Pusher(APP_KEY_PUSHER);
