import React, {Component, Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {AppSidebarHeader, AppSidebarForm, AppSidebarNav, AppSidebarFooter, AppSidebarMinimizer} from '@coreui/react';
import {navigation} from 'navigation';

class DefaultSidebar extends Component {
  getNavigator = () => {
    const {activeUser} = this.props;
    const permissions = activeUser.permissions
      .filter((permission) => permission.method === 'go')
      .map((permission) => `${permission.resourceName}_${permission.method}`);

    let {items} = navigation;
    items = items.filter((item) => !item.resource || permissions.includes(item.resource));
    return {items};
  };

  render() {
    return (
      <Fragment>
        <AppSidebarHeader />
        <AppSidebarForm />
        <AppSidebarNav {...this.props} navConfig={this.getNavigator()} />
        <AppSidebarFooter />
        <AppSidebarMinimizer className='invisible' />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeUser: state.userCurrent,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultSidebar);
