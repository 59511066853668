import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {AppHeaderDropdown} from '@coreui/react';
import {DropdownItem, DropdownMenu, DropdownToggle, Badge} from 'reactstrap';
import {Button, notification} from 'antd';
import {notifyServices} from 'services/notify';
import {storageUtil} from 'utils/storage';
import {converter} from 'utils/converter';
import {channel} from 'utils/socket';

class Notification extends Component {
  constructor() {
    super();

    this.state = {
      messages: [],
      unread: 0,
    };

    // const channel = socket.subscribe('notify');

    channel.bind('ASSIGN', (response) => {
      const data = converter.camelCaseConverter(response);
      Object.keys(data).forEach((userId) => {
        if (Number(userId) === this.getUserId()) {
          this.reloadData();

          Object.keys(data[userId]).forEach((campaignId) => {
            const notify = data[userId][campaignId];
            const message = `Chiến dịch ${notify.campaignTitle}`;
            const description = `Bạn còn ${notify.total} đơn hàng chưa gọi`;
            notification.warning({
              key: campaignId,
              message,
              description,
              btn: (
                <Button type='danger' onClick={() => this.handleViewOne(campaignId, notify.notifyId)}>
                  Đến xem
                </Button>
              ),
            });
          });
        }
      });
    });

    channel.bind('SYNC', (response) => {
      const data = converter.camelCaseConverter(response);
      if (data.userId === this.getUserId()) {
        this.reloadData();

        const success = data.status === 0;
        const message = `Chiến dịch ${data.campaign.name}`;
        if (success)
          notification.success({
            message,
            description: `Hoàn thành việc đồng bộ đơn hàng`,
          });
        else
          notification.error({
            message,
            description: `Đồng bộ đơn hàng không thành công`,
          });
      }
    });
  }

  componentDidMount() {
    this.reloadData();
  }

  getPersonId = () => this.props.activeUser.personId;

  getUserId = () => this.props.activeUser.id;

  reloadData() {
    const personId = this.getPersonId();
    if (personId)
      notifyServices.get({personId}).then((response) => {
        const {data} = response;
        this.setState({
          messages: data.content,
          unread: data.unread,
        });
      });
  }

  handleViewOne(campaignId, notifyId) {
    notification.close(campaignId);
    this.handleReadOne(campaignId, notifyId);
  }

  handleViewAll() {
    this.handleReadAll();
  }

  handleReadOne(notifyId) {
    notifyServices
      .readOne({
        personId: this.getPersonId(),
        notifyId,
      })
      .then(() => {
        this.reloadData();
      });
  }

  handleReadAll() {
    notifyServices
      .readAll({
        personId: this.getPersonId(),
      })
      .then(() => {
        this.reloadData();
      });
  }

  render() {
    const {messages, unread} = this.state;

    return (
      <AppHeaderDropdown direction='down' className='notification'>
        <DropdownToggle nav onClick={() => this.reloadData()}>
          <i className='icon-bell' />
          {unread > 0 && (
            <Badge pill color='danger'>
              {unread}
            </Badge>
          )}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header>
            <span>Thông báo</span>
            <span className='text-link' role='button' onClick={() => this.handleViewAll()}>
              Đánh dấu là đã đọc
            </span>
          </DropdownItem>
          {messages
            .filter((message) => message.total >= 0)
            .map((message) => (
              <DropdownItem
                className={message.isRead ? 'isread' : 'unread'}
                key={message.notifyId}
                onClick={() => this.handleReadOne(message.campaignId, message.notifyId)}>
                <div className='d-flex'>
                  <span className='text-danger text-bold pr-1'>{message.campaignTitle}</span>- Bạn còn
                  <span className='text-danger text-bold px-1'>{message.total}</span>đơn hàng chưa gọi
                </div>
                <div className='item-when'>{message.created}</div>
              </DropdownItem>
            ))}
          <DropdownItem header className='b-b-0' />
        </DropdownMenu>
      </AppHeaderDropdown>
    );
  }
}

function mapStateToProps() {
  return {
    activeUser: storageUtil.getActiveUser(),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
