import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {AppHeaderDropdown} from '@coreui/react';
import {DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {Icon} from 'antd';
import {userActions} from 'actions/user';
import {userServices} from 'services/user';
import {storageUtil} from 'utils/storage';

class UserSetting extends Component {
  handleChangeExtension = () => {
    this.props.shouldVisibleChangeExtension(true);
  };

  render() {
    const {activeUser} = this.props;
    return (
      <AppHeaderDropdown direction='down'>
        <DropdownToggle nav className='user-setting-toggle'>
          <strong>{activeUser.name}</strong>
          <i className='fa fa-caret-down ml-1' style={{marginBottom: 2}} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem disabled onClick={this.handleChangeExtension}>
            <Icon type='star' />
            <strong>Extension</strong>
          </DropdownItem>
          <Link to='/me'>
            <DropdownItem>
              <Icon type='user' />
              <strong>Profile</strong>
            </DropdownItem>
          </Link>
          <DropdownItem onClick={() => userServices.logout()}>
            <Icon type='logout' />
            <strong>Logout</strong>
          </DropdownItem>
        </DropdownMenu>
      </AppHeaderDropdown>
    );
  }
}

function mapStateToProps() {
  return {
    activeUser: storageUtil.getActiveUser(),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      shouldVisibleChangeExtension: userActions.shouldVisibleChangeExtension,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserSetting);
