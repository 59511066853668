import axios from 'axios';
import {apiConstants} from 'constants/api';
import {authorizationHeader} from 'utils/request';

const getProvinces = () =>
  axios.get(apiConstants.GET_PROVINCE, {
    headers: authorizationHeader(),
  });

const getTicketTypes = () =>
  axios.get(apiConstants.GET_TICKET_TYPE, {
    headers: authorizationHeader(),
  });

const getReceiveChannels = () =>
  axios.get(apiConstants.GET_RECEIVE_CHANNEL, {
    headers: authorizationHeader(),
  });

const getTicketPriorityTypes = () =>
  axios.get(apiConstants.GET_TICKET_PRIORITY_TYPE, {
    headers: authorizationHeader(),
  });

const getChannels = () =>
  axios.get(apiConstants.GET_CHANNEL, {
    headers: authorizationHeader(),
  });

const getBranches = () =>
  axios.get(apiConstants.GET_DEPARTMENT, {
    headers: authorizationHeader(),
  });

const getStatusPermission = () =>
  axios.get(apiConstants.GET_STATUS_PERMISSION, {
    headers: authorizationHeader(),
  });

const getStatusFlows = () =>
  axios.get(apiConstants.GET_STATUS_FLOW, {
    headers: authorizationHeader(),
  });

const getLine = () =>
  axios.get(apiConstants.GET_LINE, {
    headers: authorizationHeader(),
  });

const getAsPersons = () =>
  axios.get(apiConstants.GET_PERSON, {
    headers: authorizationHeader(),
  });

const getCsPersons = () =>
  axios.get(apiConstants.GET_PERSON_CS, {
    headers: authorizationHeader(),
  });

const getAnswerStatus = () =>
  axios.get(apiConstants.GET_ANSWER_STATUS, {
    headers: authorizationHeader(),
  });

const getProcess = () =>
  axios.get(apiConstants.GET_PROCESS, {
    headers: authorizationHeader(),
  });

export const staticServices = {
  getProvinces,
  getTicketTypes,
  getReceiveChannels,
  getTicketPriorityTypes,
  getChannels,
  getBranches,
  getStatusPermission,
  getStatusFlows,
  getLine,
  getAsPersons,
  getCsPersons,
  getAnswerStatus,
  getProcess,
};
