import React from 'react';
import {Route} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {staticActions} from 'actions/static';
import {userActions} from 'actions/user';
import {cookieUtil} from 'utils/cookie';
import lodash from 'lodash';
import ScreenLoading from 'containers/assets/ScreenLoading';
import withSessionTimeout from 'containers/hocs/sessionTimeout';
import withAuthorization from 'containers/hocs/routeProtecter';

class PrivateRoute extends React.Component {
  componentDidMount() {
    if (!window.TekoID.user.isLoggedIn()) {
      window.TekoID.user.login();
    } else {
      this.props.getStaticData();
    }
  }

  render() {
    const accessToken = cookieUtil.getAccessToken();
    if (!accessToken) {
      return <ScreenLoading />;
    }

    const {activeUser, getCurrentUserInfo} = this.props;
    if (lodash.isEmpty(activeUser)) {
      getCurrentUserInfo();
      return <ScreenLoading />;
    }

    const {component: Component} = this.props;
    return <Route render={(props) => <Component {...props} />} />;
  }
}

function mapStateToProps(state) {
  return {
    activeUser: state.userCurrent,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUserInfo: userActions.getCurrentUserInfo,
      getStaticData: staticActions.getStaticData,
    },
    dispatch,
  );
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withAuthorization,
  withSessionTimeout,
)(PrivateRoute);
