const REACT_APP_REDIRECT_LOGOUT_URI = `${window.location.protocol}//${window.location.host}`;

const {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_AUTH_URL,
  REACT_APP_SCOPES,
  REACT_APP_KEY_PUSHER,
  REACT_APP_PUSHER_TEKO_URL,
} = window;

export default {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_AUTH_URL,
  REACT_APP_SCOPES,
  REACT_APP_KEY_PUSHER,
  REACT_APP_PUSHER_TEKO_URL,
  REACT_APP_REDIRECT_LOGOUT_URI
};
