import {staticActionTypes} from 'actions/static';

export default (
  state = {
    provinces: {},
    ticketTypes: {},
    noteTypes: {},
    receiveChannels: {},
    ticketPriorityTypes: {},
    channels: {},
    departments: {},
    branches: {},
    statusFlows: {},
    linesNumber: {},
    asPersons: {},
    csPersons: {},
    answerStatus: {},
    processes: {},
  },
  action,
) => {
  switch (action.type) {
    case staticActionTypes.GET_PROVINCE:
      return {...state, provinces: action.data};
    case staticActionTypes.GET_TICKET_TYPE:
      return {...state, ticketTypes: action.data};
    case staticActionTypes.GET_NOTE_TYPE:
      return {...state, noteTypes: action.data};
    case staticActionTypes.GET_LINE:
      return {...state, lines: action.data};
    case staticActionTypes.GET_RECEIVE_CHANNEL:
      return {...state, receiveChannels: action.data};
    case staticActionTypes.GET_TICKET_PRIORITY_TYPE:
      return {...state, ticketPriorityTypes: action.data};
    case staticActionTypes.GET_CHANNEL:
      return {...state, channels: action.data};
    case staticActionTypes.GET_DEPARTMENT:
      return {...state, departments: action.data};
    case staticActionTypes.GET_BRANCH:
      return {...state, branches: action.data};
    case staticActionTypes.GET_STATUS_FLOW:
      return {...state, statusFlows: action.data};
    case staticActionTypes.GET_LINE_NUMBER:
      return {...state, linesNumber: action.data};
    case staticActionTypes.GET_AS_PERSON:
      return {...state, asPersons: action.data};
    case staticActionTypes.GET_CS_PERSON:
      return {...state, csPersons: action.data};
    case staticActionTypes.GET_ANSWER_STATUS:
      return {...state, answerStatus: action.data};
    case staticActionTypes.GET_PROCESS:
      return {...state, processes: action.data};
    default:
      return state;
  }
};
