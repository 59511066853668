import axios from 'axios';
import {apiConstants} from 'constants/api';
import {authorizationHeader} from 'utils/request';
import {converter} from 'utils/converter';

const fetch = (request) =>
  axios.get(apiConstants.CUSTOMER_FETCH, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });

const getByCustomer = (request) =>
  axios.get(apiConstants.CUSTOMER_GET(request.customerId), {
    headers: authorizationHeader(),
  });

const getByOrder = (request) =>
  axios.get(apiConstants.CUSTOMER_ORDER_GET(request.orderId), {
    headers: authorizationHeader(),
  });

const get = (request) => (request.customerId ? getByCustomer(request) : getByOrder(request));

const getExtraOrder = (request) =>
  axios.get(apiConstants.CUSTOMER_GET_EXTRA_ORDER, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });

const createCustomer = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.post(apiConstants.CUSTOMER_CREATE, body, {
    headers: authorizationHeader(),
  });
};

const updateCustomer = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.put(apiConstants.CUSTOMER_UPDATE(request.customerId), body, {
    headers: authorizationHeader(),
  });
};

const getHistoryCall = (request) =>
  axios.get(apiConstants.CUSTOMER_HISTORY_CALL, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });

const getHistoryOrder = (request) =>
  axios.get(apiConstants.CUSTOMER_HISTORY_ORDER, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });

const getHistoryTicket = (request) =>
  axios.get(apiConstants.CUSTOMER_HISTORY_TICKET, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });

const getHistoryCallOut = (request) =>
  axios.get(apiConstants.CUSTOMER_HISTORY_CALLOUT, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });

const getInboundOrder = (request) =>
  axios.get(apiConstants.INBOUND_ORDER_GET(request.orderId), {
    headers: authorizationHeader(),
  });

const getInboundSaleOrder = (request) =>
  axios.get(apiConstants.INBOUND_ORDER_SEARCH, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });

export const customerServices = {
  fetch,
  getByCustomer,
  get,
  getExtraOrder,
  createCustomer,
  updateCustomer,

  getHistoryCall,
  getHistoryOrder,
  getHistoryTicket,
  getHistoryCallOut,

  getInboundOrder,
  getInboundSaleOrder,
};
