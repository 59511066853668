import {storageUtil} from 'utils/storage';

export const callingActionTypes = {
  CALLING_STATUS_SET: 'CALLING_STATUS_SET',
  CALLING_STATUS_RESET: 'CALLING_STATUS_RESET',
};

const setCallingStatus = (callingStatus) => (dispatch) => {
  storageUtil.setCallingStatus(callingStatus);
  dispatch({
    type: callingActionTypes.CALLING_STATUS_SET,
    data: callingStatus,
  });
};

const resetCallingStatus = () => (dispatch) => {
  storageUtil.clearCallingStatus();
  dispatch({
    type: callingActionTypes.CALLING_STATUS_RESET,
  });
};

export const callingActions = {
  setCallingStatus,
  resetCallingStatus,
};
