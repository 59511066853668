import {createStore, applyMiddleware, compose} from 'redux';
import ReduxThunk from 'redux-thunk';
import {rootReducer} from 'reducers';

export default function configureStore(initialState = {}) {
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewares = [ReduxThunk];
  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
}
