import {pageActionTypes} from 'actions/pages';

export default (state = {}, action) => {
  switch (action.type) {
    case pageActionTypes.CALL_LIST_SAVE:
      return {...state, callList: action.data};
    case pageActionTypes.TICKET_LIST_SAVE:
      return {...state, ticketList: action.data};
    case pageActionTypes.CUSTOMER_LIST_SAVE:
      return {...state, customerList: action.data};
    case pageActionTypes.CAMPAIGN_LIST_SAVE:
      return {...state, campaignList: action.data};
    case pageActionTypes.RESPONSE_LIST_SAVE:
      return {...state, responseList: action.data};
    case pageActionTypes.SURVEY_LIST_SAVE:
      return {...state, surveyList: action.data};
    case pageActionTypes.SURVEY_CALL_SAVE:
      return {...state, surveyCall: action.data};
    case pageActionTypes.DEPARTMENT_LIST_SAVE:
      return {...state, departmentList: action.data};
    default:
      return state;
  }
};
