import React, {Component, Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {AppHeaderDropdown} from '@coreui/react';
import {DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {Badge, Modal, Button, Spin, Icon} from 'antd';
import {userActions} from 'actions/user';
import {userServices} from 'services/user';
import {agentConstants} from 'constants/user';

class UserExtension extends Component {
  constructor() {
    super();

    this.state = {
      isLoadingStatus: false,
      isLoadingExtensions: false,
      isLoadingButtons: {},

      extensions: [],
      mode: '',
    };
  }

  componentDidMount() {
    this.reloadAgent();
  }

  componentDidUpdate(prevProps) {
    const {activeAgent} = this.props;
    if (prevProps.activeAgent.shouldVisibleModalExtension !== activeAgent.shouldVisibleModalExtension) {
      if (activeAgent.shouldVisibleModalExtension) {
        this.loadExtensions(agentConstants.modeTypes.LOGIN);
      }
    }
    if (prevProps.activeAgent.shouldVisibleChangeExtension !== activeAgent.shouldVisibleChangeExtension) {
      if (activeAgent.shouldVisibleChangeExtension) {
        this.loadExtensions(activeAgent.mode);
      }
    }
  }

  reloadAgent = () => {
    this.props.getCurrentAgentInfo();
  };

  loadExtensions = (mode) => {
    if (mode) this.setState({mode});

    this.setState({visibleModalExtension: true, isLoadingExtensions: true});
    userServices
      .fetchExtension()
      .then((response) => {
        this.setState({
          extensions: response.data.map((item) => ({
            number: item.extension,
            available: item.status === agentConstants.statusTypes.AVAILABLE,
          })),
        });
      })
      .catch(() => {})
      .then(() => this.setState({isLoadingExtensions: false}));
  };

  handleChangeMode = (mode) => {
    const {activeAgent} = this.props;
    if (activeAgent.extension)
      this.handleChangeStatus(agentConstants.statusTypes.AVAILABLE, mode, activeAgent.extension);
    else this.loadExtensions(mode);
  };

  handleChangeExtension = (index) => {
    const {extensions, isLoadingButtons, mode} = this.state;

    isLoadingButtons[index] = true;
    this.setState({isLoadingButtons});
    userServices
      .changeStatusExtension({
        extension: extensions[index] && extensions[index].number,
        mode: mode.toUpperCase(),
        status: agentConstants.statusTypes.AVAILABLE.toUpperCase(),
      })
      .then(() => {})
      .catch(() => {})
      .then(() => {
        this.setState({visibleModalExtension: false, isLoadingButtons: {}});
        this.reloadAgent();
      });
  };

  handleChangeStatus = (status, mode, extension) => {
    this.setState({isLoadingStatus: true});
    userServices
      .changeStatusExtension({
        mode: mode && mode.toUpperCase(),
        status: status.toUpperCase(),
        extension,
      })
      .then(() => {})
      .catch(() => {})
      .then(() => {
        this.setState({isLoadingStatus: false});
        this.reloadAgent();
      });
  };

  render() {
    const {activeAgent} = this.props;
    const {extensions, isLoadingStatus, isLoadingExtensions, isLoadingButtons} = this.state;

    const isLogin = activeAgent.mode === agentConstants.modeTypes.LOGIN;
    const unavailable = activeAgent.status === agentConstants.statusTypes.UNAVAILABLE;

    const modeValue = agentConstants.modeValues[activeAgent.mode] || {};
    const statusValue = agentConstants.statusValues[activeAgent.status] || {};

    return (
      <AppHeaderDropdown direction='down' className='agent-status'>
        <DropdownToggle nav className='agent-status-toggle'>
          <Badge status={modeValue.color} />
          <strong>{modeValue.text}</strong>
          <strong className={unavailable ? 'text-danger' : 'text-success'}>{statusValue.text}</strong>
          <div>
            <strong>{activeAgent.extension ? `(Ext: ${activeAgent.extension})` : ``}</strong>
            <Icon type='loading' className={isLoadingStatus ? 'visible' : 'invisible'} />
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {unavailable || isLogin ? (
            <Fragment>
              <DropdownItem onClick={() => this.handleChangeMode(agentConstants.modeTypes.INBOUND_CALL)}>
                <Badge status='success' />
                <strong>Inbound Call</strong>
              </DropdownItem>
              <DropdownItem onClick={() => this.handleChangeMode(agentConstants.modeTypes.OUTBOUND_CALL)}>
                <Badge status='processing' />
                <strong>Outbound Call</strong>
              </DropdownItem>
              <DropdownItem onClick={() => this.handleChangeMode(agentConstants.modeTypes.TICKET_PROCESS)}>
                <Badge status='warning' />
                <strong>Xử lý Ticket</strong>
              </DropdownItem>
            </Fragment>
          ) : (
            <DropdownItem onClick={() => this.handleChangeStatus(agentConstants.statusTypes.UNAVAILABLE)}>
              <Badge status='default' />
              <strong>Unavailable</strong>
              <strong className='d-none'>Idle (Không hoạt động)</strong>
            </DropdownItem>
          )}
          <Modal
            className='modal-extension'
            title='Lựa chọn Extension của bạn'
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.setState({visibleModalExtension: false})}
            visible={this.state.visibleModalExtension}>
            <Spin spinning={isLoadingExtensions}>
              {extensions.map((extension, index) => (
                <Button
                  className='button-extension'
                  key={index}
                  loading={isLoadingButtons[index]}
                  type={extension.number === activeAgent.extension ? 'success' : 'default'}
                  disabled={extension.number !== activeAgent.extension && !extension.available}
                  onClick={() => this.handleChangeExtension(index)}>
                  {extension.number}
                </Button>
              ))}
            </Spin>
          </Modal>
        </DropdownMenu>
      </AppHeaderDropdown>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeAgent: state.userAgent,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentAgentInfo: userActions.getCurrentAgentInfo,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserExtension);
