import {userActionTypes} from 'actions/user';

export default (state = {}, action) => {
  switch (action.type) {
    case userActionTypes.USER_LOGIN:
      return action.user;
    default:
      return state;
  }
};
