import axios from 'axios';
import {apiConstants} from 'constants/api';
import {authorizationHeader} from 'utils/request';
import {converter} from 'utils/converter';

const reportCallcenter = (request) =>
  axios.get(apiConstants.REPORT_CALLCENTER, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });
const exportCallcenter = (request) =>
  axios.create().get(apiConstants.EXPORT_CALLCENTER, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });

const reportCallnoteDay = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.post(apiConstants.REPORT_CALLNOTE_DAY, body, {
    headers: authorizationHeader(),
  });
};
const exportCallnoteDay = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.create().post(apiConstants.EXPORT_CALLNOTE_DAY, body, {
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });
};

const reportCallnotePure = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.post(apiConstants.REPORT_CALLNOTE_PURE, body, {
    headers: authorizationHeader(),
  });
};
const exportCallnotePure = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.create().post(apiConstants.EXPORT_CALLNOTE_PURE, body, {
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });
};

const reportCallPeriod = (request) =>
  axios.get(apiConstants.REPORT_CALL_PERIOD, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });
const exportCallPeriod = (request) =>
  axios.create().get(apiConstants.EXPORT_CALL_PERIOD, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });

const reportTicketTypeChannel = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.post(apiConstants.REPORT_TICKET_TYPE_CHANNEL(request.kind), body, {
    headers: authorizationHeader(),
  });
};
const exportTicketTypeChannel = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.create().post(apiConstants.EXPORT_TICKET_TYPE_CHANNEL(request.kind), body, {
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });
};

const reportTicketTypeDay = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.post(apiConstants.REPORT_TICKET_BY_DAY(request.kind), body, {
    headers: authorizationHeader(),
  });
};
const exportTicketTypeDay = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.create().post(apiConstants.EXPORT_TICKET_BY_DAY(request.kind), body, {
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });
};

const reportTicketTypeProcess = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.post(apiConstants.REPORT_TICKET_TYPE_PROCESS, body, {
    headers: authorizationHeader(),
  });
};
const exportTicketTypeProcess = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.create().post(apiConstants.EXPORT_TICKET_TYPE_PROCESS, body, {
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });
};

const reportTicketTypeStatus = (request) =>
  axios.get(apiConstants.REPORT_TICKET_TYPE_STATUS(request.kind), {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });
const exportTicketTypeStatus = (request) =>
  axios.create().get(apiConstants.EXPORT_TICKET_TYPE_STATUS(request.kind), {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });

const reportCallnotePerson = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.post(apiConstants.REPORT_CALLNOTE_PERSON, body, {
    headers: authorizationHeader(),
  });
};
const exportCallnotePerson = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.create().post(apiConstants.EXPORT_CALLNOTE_PERSON, body, {
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });
};

const reportPersonTimeline = (request) =>
  axios.get(apiConstants.REPORT_PERSON_TIMELINE, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
  });
const exportPersonTimeline = (request) =>
  axios.create().get(apiConstants.EXPORT_PERSON_TIMELINE, {
    params: converter.snakeCaseConverter(request),
    headers: authorizationHeader(),
    responseType: 'arraybuffer',
  });

export const reportServices = {
  reportCallcenter,
  exportCallcenter,
  reportCallnoteDay,
  exportCallnoteDay,
  reportCallnotePure,
  exportCallnotePure,
  reportCallPeriod,
  exportCallPeriod,

  reportTicketTypeChannel,
  exportTicketTypeChannel,
  reportTicketTypeDay,
  exportTicketTypeDay,
  reportTicketTypeProcess,
  exportTicketTypeProcess,
  reportTicketTypeStatus,
  exportTicketTypeStatus,

  reportCallnotePerson,
  exportCallnotePerson,
  reportPersonTimeline,
  exportPersonTimeline,
};
