const modeTypes = {
  LOGIN: 'login',
  INBOUND_CALL: 'inbound_call',
  OUTBOUND_CALL: 'outbound_call',
  TICKET_PROCESS: 'ticket_process',
};

const modeValues = {
  [modeTypes.LOGIN]: {color: 'default', text: 'Online'},
  [modeTypes.INBOUND_CALL]: {color: 'success', text: 'Inbound Call'},
  [modeTypes.OUTBOUND_CALL]: {color: 'processing', text: 'Outbound Call'},
  [modeTypes.TICKET_PROCESS]: {color: 'warning', text: 'Xử lý Ticket'},
};

const statusTypes = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
};

const statusValues = {
  [statusTypes.AVAILABLE]: {text: 'Available'},
  [statusTypes.UNAVAILABLE]: {text: 'Unavailable'},
};

export const agentConstants = {
  modeTypes,
  modeValues,
  statusTypes,
  statusValues,
};