import React, {Component, Fragment} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {browserHistory} from 'utils/history';
import {config} from 'config';
// Styles
import 'antd/dist/antd.min.css';
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import 'scss/style.scss';
// Pages
import Page403 from 'views/Pages/Page403';
import Page404 from 'views/Pages/Page404';
import Page500 from 'views/Pages/Page500';
// Containers
import DefaultLayout from 'containers/components/DefaultLayout';
import PrivateRoute from 'containers/PrivateRouter';

class App extends Component {
  render() {
    return (
      <Router history={browserHistory}>
        <Fragment>
          <Switch>
            <Route exact path="/403" name="Page 403" component={Page403} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </Fragment>
      </Router>
    );
  }
}

config.run();

export default App;
