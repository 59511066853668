import axios from 'axios';
import {apiConstants} from 'constants/api';
import {authorizationHeader} from 'utils/request';
import {converter} from 'utils/converter';

const get = (request) =>
  axios.get(apiConstants.ORDER_GET_NOTIFY(request.personId), {
    headers: authorizationHeader(),
  });

const readAll = (request) => {
  const body = converter.snakeCaseConverter({markAll: true, notifyId: 0});
  return axios.put(apiConstants.ORDER_GET_NOTIFY(request.personId), body, {
    headers: authorizationHeader(),
  });
};

const readOne = (request) => {
  const body = converter.snakeCaseConverter(request);
  return axios.put(apiConstants.ORDER_GET_NOTIFY(request.personId), body, {
    headers: authorizationHeader(),
  });
};

export const notifyServices = {
  get,
  readAll,
  readOne,
};
