import React, {Component, Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';
import {Modal, Icon, Table, Button, Tag, Form, Input, Select} from 'antd';
import Notification from 'react-web-notification';
import icon from 'containers/assets/customcare.png';
import {callingActions} from 'actions/calling';
import {customerServices} from 'services/customer';
import {customerConstants} from 'constants/customer';
import {agentConstants} from 'constants/user';
import {browserHistory} from 'utils/history';
import {converter} from 'utils/converter';
import {alertUtil} from 'utils/alert';
import {channel} from 'utils/socket';
import routes from 'routes';

const FormItem = Form.Item;
const SelectOption = Select.Option;

class PopupCalling extends Component {
  constructor() {
    super();

    this.state = {
      visibleModalCalling: false,
      customers: [],

      search: {},

      ignore: true,
      notification: {
        title: '',
      },
    };

    // const channel = socket.subscribe('call_center');

    channel.bind('income_call', (response) => {
      const data = converter.camelCaseConverter(response);
      const extension = Number(data.extension);
      const callingNumber = data.phoneNo;

      const {activeAgent} = this.props;
      const isInbound = activeAgent.mode === agentConstants.modeTypes.INBOUND_CALL;
      const isAvailable = activeAgent.status === agentConstants.statusTypes.AVAILABLE;
      const isCalling = extension === activeAgent.extension && data.personId === activeAgent.personId;
      if (isInbound && isAvailable && isCalling) {
        this.showNotification(data);
        this.setState(
          (state) => ({
            branch: `Nhánh ${data.line || '✗'}: ${data.lineName || ''}`,
            calling: callingNumber,
            search: Object.assign({}, state.search, {
              label: 'phone',
              value: callingNumber,
            }),
          }),
          () => this.handleClickSearch(),
        );

        const callingStatus = {
          ...data,
          isCalling: true,
          callingNumber,
          extension,
        };
        this.props.setCallingStatus(callingStatus);
      }
    });
  }

  showNotification = (data) => {
    if (this.state.ignore) return;
    this.setState({
      notification: {
        title: 'Teko Customer Service',
        options: {
          body:
            data.line && data.lineName
              ? `Cuộc gọi đến: ${data.phoneNo}\nNhánh ${data.line}: ${data.lineName}`
              : `Cuộc gọi đến: ${data.phoneNo}`,
          icon,
          tag: Math.floor(Date.now() / 10000),
        },
      },
    });
  };

  alertNotification = (message) => {
    alertUtil.error(message);
    this.setState({ignore: true});
  };

  handleChangeLabel = (label) => {
    this.setState((state) => ({
      search: Object.assign({}, state.search, {
        label,
      }),
    }));
  };

  handleChangeValue = (event) => {
    const {value} = event.target;
    this.setState((state) => ({
      search: Object.assign({}, state.search, {
        value,
      }),
    }));
  };

  handleClickCalling = () => {
    this.setState(
      (state) => ({
        search: {
          label: 'phone',
          value: state.calling,
        },
      }),
      () => this.handleClickSearch(),
    );
  };

  handleClickSearch = () => {
    this.setState(
      (state) => ({
        searchSubmit: state.search,
        pagination: {current: 1, total: 0, pageSize: 5},
      }),
      () => this.preloadData(),
    );
  };

  preloadData = () => {
    this.setState(
      (state) => ({
        search: state.searchSubmit,
      }),
      () => this.reloadData(),
    );
  };

  reloadData = () => {
    const {search, pagination} = this.state;
    const request = {
      [search.label]: search.value,
      page: pagination.current - 1,
      size: pagination.pageSize,
    };

    this.setState({visibleModalCalling: true, isLoading: true});
    customerServices
      .fetch(request)
      .then((response) => {
        const customers = response.data.content;
        this.setState((state) => ({
          customers,
          pagination: Object.assign({}, state.pagination, {
            total: response.data.totalElements,
          }),
        }));
        if (customers.length === 1) this.handleAssignCalling(customers[0]);
      })
      .catch(() => {})
      .then(() => {
        this.setState({isLoading: false});
      });
  };

  handleTableChange = (pagination) => {
    this.setState({pagination}, () => this.preloadData());
  };

  handleCreateCalling() {
    this.setState({visibleModalCalling: false});
    browserHistory.push(routes.customerCreate.path);
  }

  handleAssignCalling(customer) {
    this.setState({visibleModalCalling: false});
    if (customer.customerId) browserHistory.push(routes.customerDetail.path.replace(':id', customer.customerId));
    else if (customer.orderId) browserHistory.push(routes.customerOrderDetail.path.replace(':id', customer.orderId));
  }

  render() {
    const {calling, branch, isLoading} = this.state;
    const {customers, pagination, search} = this.state;
    const {ignore, notification} = this.state;

    const emptyText = (
      <Button icon='plus-circle' type='success' onClick={() => this.handleCreateCalling()}>
        Tạo mới
      </Button>
    );

    const RowWrapper = (props) => (
      <tr {...props} title='Chuyển tới màn hình chi tiết' className='cursor-pointer'>
        {props.children}
      </tr>
    );

    return (
      <Fragment>
        <Notification
          ignore={ignore}
          notSupported={() => this.alertNotification('Trình duyệt không hỗ trợ thông báo')}
          onPermissionDenied={() => this.alertNotification('Vui lòng cho phép bật thông báo')}
          onPermissionGranted={() => this.setState({ignore: false})}
          timeout={5000}
          {...notification}
        />

        <Modal
          className='modal-stretch modal-calling'
          title={
            <div className='header-calling'>
              <Icon className='icon-calling' type='phone' theme='twoTone' />
              <strong className='branch-calling'>{branch}</strong>
              <strong
                className='phone-calling cursor-pointer'
                title='Tìm kiếm'
                role='button'
                onClick={this.handleClickCalling}>
                {calling}
              </strong>
            </div>
          }
          destroyOnClose
          maskClosable={false}
          onCancel={() => this.setState({visibleModalCalling: false})}
          visible={this.state.visibleModalCalling}>
          <Form>
            <Row>
              <Col xl={4} md={5}>
                <FormItem
                  label={
                    <Select value={search.label} style={{width: 150}} onChange={this.handleChangeLabel}>
                      <SelectOption value='phone'>Số điện thoại</SelectOption>
                      <SelectOption value='orderCode'>Mã đơn hàng</SelectOption>
                      <SelectOption value='memberCardNo'>Thẻ thành viên</SelectOption>
                      <SelectOption value='taxCode'>Mã số thuế</SelectOption>
                      <SelectOption value='name'>Tên khách hàng</SelectOption>
                      <SelectOption value='customerCode'>Mã khách hàng</SelectOption>
                    </Select>
                  }>
                  <Input value={search.value} onChange={this.handleChangeValue} />
                </FormItem>
              </Col>
              <Col className='d-sm-flex align-items-end justify-content-end justify-content-md-start'>
                <FormItem className='ant-form-item-button-line'>
                  <Button type='primary' icon='search' onClick={this.handleClickSearch}>
                    Tìm kiếm
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col>
              <Table
                bordered
                locale={{emptyText}}
                loading={isLoading}
                dataSource={customers}
                rowKey={(record) => record.customerId || record.orderId}
                pagination={pagination}
                components={{body: {row: RowWrapper}}}
                onChange={this.handleTableChange}
                onRow={(record) => ({
                  onClick: () => this.handleAssignCalling(record),
                })}
                columns={[
                  {
                    title: 'Mã KH',
                    dataIndex: 'source',
                    render: (text, record) => (
                      <div className='text-center'>
                        <div>{record.source}</div>
                        <div>{record.sourceId}</div>
                      </div>
                    ),
                  },
                  {
                    title: 'Loại KH',
                    dataIndex: 'customerType',
                    render: (text, record) => {
                      let customerType = '';
                      if (record.customerType === customerConstants.customerTypes.PERSONAL) customerType = 'Cá nhân';
                      if (record.customerType === customerConstants.customerTypes.COMPANY)
                        customerType = 'Doanh nghiệp';
                      if (record.customerType === customerConstants.customerTypes.GCAFE) customerType = 'Phòng máy';
                      return <div>{customerType}</div>;
                    },
                  },
                  {title: 'Tên', dataIndex: 'name'},
                  {title: 'Mã số thuế', dataIndex: 'taxCode'},
                  {title: 'Địa chỉ', dataIndex: 'address'},
                  {title: 'Thẻ thành viên', dataIndex: 'memberCardNo'},
                  {
                    title: 'Hạng thẻ',
                    dataIndex: 'memberLevel',
                    render: (text, record) => {
                      let memberLevel = '';
                      let memberColor = '';
                      if (record.memberLevel === customerConstants.memberLevels.NORMAL) {
                        memberLevel = 'Normal';
                        memberColor = 'blue';
                      }
                      if (record.memberLevel === customerConstants.memberLevels.GOLD) {
                        memberLevel = 'Gold';
                        memberColor = 'gold';
                      }
                      if (record.memberLevel === customerConstants.memberLevels.DIAMOND) {
                        memberLevel = 'Diamond';
                        memberColor = 'cyan';
                      }
                      return (
                        <div className='text-center'>
                          {memberLevel && (
                            <Tag style={{width: 65}} color={memberColor}>
                              {memberLevel}
                            </Tag>
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    title: 'Trạng thái',
                    dataIndex: 'cardStatus',
                    render: (text, record) => {
                      let cardStatus = '';
                      let cardColor = '';
                      if (record.memberCardStatus === customerConstants.memberCardStatus.CANCELED) {
                        cardStatus = 'Đã hủy';
                        cardColor = 'purple';
                      }
                      if (record.memberCardStatus === customerConstants.memberCardStatus.DISABLED) {
                        cardStatus = 'Đã khóa';
                        cardColor = 'volcano';
                      }
                      if (record.memberCardStatus === customerConstants.memberCardStatus.ACTIVE) {
                        cardStatus = 'Hoạt động';
                        cardColor = 'green';
                      }
                      return (
                        <div className='text-center'>
                          {cardStatus && (
                            <Tag style={{width: 75}} color={cardColor}>
                              {cardStatus}
                            </Tag>
                          )}
                        </div>
                      );
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeAgent: state.userAgent,
    callingStatus: state.callingStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCallingStatus: callingActions.setCallingStatus,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopupCalling);
