import {callingActionTypes} from 'actions/calling';

export default (state = {}, action) => {
  switch (action.type) {
    case callingActionTypes.CALLING_STATUS_SET:
      return {...state, ...action.data};
    case callingActionTypes.CALLING_STATUS_RESET:
      return {};
    default:
      return state;
  }
};
