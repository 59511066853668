import React from 'react';
import {userServices} from 'services/user';

const MINUTE = 60 * 2;

// This function takes a component...
function withSessionTimeout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component<Props> {
    constructor(props) {
      super(props);
      this.state = {
        signoutTime: 1000 * 60 * MINUTE,
      };
    }

    componentDidMount() {
      // ... that takes care of the subscription...
      ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'].forEach((event) => {
        window.addEventListener(event, this.resetTimeout);
      });

      this.setTimeout();
    }

    clearTimeout = () => {
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeout();
      this.setTimeout();
    };

    logout = () => {
      // clear the session
      userServices.logout();
    };

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withSessionTimeout;
