import moment from 'moment/moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

const DATE_FORMAT_CLIENT = 'DD/MM/YYYY';
const MONTH_FORMAT_CLIENT = 'MM/YYYY';
const DATETIME_FORMAT_CLIENT = 'DD/MM/YYYY HH:mm:ss';
const DATE_FORMAT_SERVER = 'YYYY-MM-DD';
const DATETIME_FORMAT_SERVER = 'YYYY-MM-DD HH:mm:ss';

const parseServerDateToClientMoment = (serverDate) => (serverDate ? moment(serverDate) : null);

const parseServerDateToClientDate = (serverDate) => (serverDate ? moment(serverDate).format(DATE_FORMAT_CLIENT) : '');
const parseServerDateToClientDateTime = (serverDate) =>
  serverDate ? moment(serverDate).format(DATETIME_FORMAT_CLIENT) : '';

const parseClientMomentToServerDate = (clientMoment) => (clientMoment ? clientMoment.format(DATE_FORMAT_SERVER) : '');
const parseClientMomentToServerDateTime = (clientMoment) =>
  clientMoment ? clientMoment.format(DATETIME_FORMAT_SERVER) : '';

const parseMomentToClientDate = (clientMoment) => (clientMoment ? clientMoment.format(DATE_FORMAT_CLIENT) : '');
const parseMomentToClientDateTime = (clientMoment) => (clientMoment ? clientMoment.format(DATETIME_FORMAT_CLIENT) : '');

const convertSecondToTime = (second) => moment.duration(second, 'seconds').format('H:mm:ss', {trim: !second});

export const formatConstants = {
  DATETIME_FORMAT_CLIENT,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
  MONTH_FORMAT_CLIENT,
};

export const momentUtil = {
  parseServerDateToClientMoment,

  parseServerDateToClientDate,
  parseServerDateToClientDateTime,

  parseClientMomentToServerDate,
  parseClientMomentToServerDateTime,

  parseMomentToClientDate,
  parseMomentToClientDateTime,

  convertSecondToTime,
};
