import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import {Nav} from 'reactstrap';
import logo from 'containers/assets/logo.png';
import UserWorkingTime from 'containers/fragments/UserWorkingTime';
import UserExtension from 'containers/fragments/UserExtension';
import Notification from 'containers/fragments/Notification';
import UserSetting from 'containers/fragments/UserSetting';
import routes from 'routes';

class DefaultHeader extends Component {
  render() {
    return (
      <Fragment>
        <div className='d-flex'>
          <AppNavbarBrand
            className='d-none d-lg-inline-flex pb-2'
            full={{src: logo, width: 90, height: 25, alt: 'Teko Logo'}}
            minimized={{src: logo, width: 30, height: 25, alt: 'Teko Logo'}}
          />
          <AppSidebarToggler className='d-lg-none' mobile />
          <AppSidebarToggler className='d-lg-block d-none' />
          <UserWorkingTime />
        </div>
        <Nav className='pr-5' navbar>
          <div className='header-question-line'>
            <Link to={routes.guide.path}>
              <i className='icon-question cursor-pointer' title='FAQ' />
            </Link>
          </div>
          <Notification />
          <UserExtension />
          <UserSetting />
        </Nav>
      </Fragment>
    );
  }
}

export default DefaultHeader;
