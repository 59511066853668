import React, {Component} from 'react';

// This function takes a component...
function withDocument(WrappedComponent) {
  // ...and returns another component...
  return class extends Component<Props> {
    componentDidMount() {
      // ... that takes care of the subscription...
      document.title = this.props.title;
    }

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withDocument;
