import {cookieUtil} from 'utils/cookie';

export const authorizationHeader = () => ({
  Authorization: `Bearer ${cookieUtil.getAccessToken()}`,
});

export const authorizationHeaderFormData = () => ({
  'Content-Type': 'multipart/form-data',
  ...authorizationHeader(),
});

export const authorizationHeaderJson = () => ({
  'Content-Type': 'application/json',
  ...authorizationHeader(),
});
