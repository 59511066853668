const storageConstants = {
  ACTIVE_USER: 'activate_user',
  ACTIVE_AGENT: 'activate_agent',
  CALLING_STATUS: 'calling_status',
  CALLING_OUT: 'calling_out',
  STATUS_PERMISSION: 'status_permission',

  TICKET_FORM_VALUE: 'ticket_form_value',
  TICKET_FORM_STATE: 'ticket_form_state',
};

const setItemObject = (key, object) => localStorage.setItem(key, JSON.stringify(object));
const getItemObject = (key) => JSON.parse(localStorage.getItem(key)) || {};
const removeItemObject = (key) => localStorage.removeItem(key);

const setActiveUser = (user) => setItemObject(storageConstants.ACTIVE_USER, user);
const setActiveAgent = (agent) => setItemObject(storageConstants.ACTIVE_AGENT, agent);
const setStatusPermission = (permission) => setItemObject(storageConstants.STATUS_PERMISSION, permission);
const setCallingStatus = (status) => setItemObject(storageConstants.CALLING_STATUS, status);
const setCallingOut = (callout) => setItemObject(storageConstants.CALLING_OUT, callout);

const getActiveUser = () => getItemObject(storageConstants.ACTIVE_USER);
const getActiveAgent = () => getItemObject(storageConstants.ACTIVE_AGENT);
const getStatusPermission = () => getItemObject(storageConstants.STATUS_PERMISSION);
const getCallingStatus = () => getItemObject(storageConstants.CALLING_STATUS);
const getCallingOut = () => getItemObject(storageConstants.CALLING_OUT);

const clearCallingStatus = () => removeItemObject(storageConstants.CALLING_STATUS);

const setTicketFormValue = (value) => setItemObject(storageConstants.TICKET_FORM_VALUE, value);
const getTicketFormValue = () => getItemObject(storageConstants.TICKET_FORM_VALUE);

const setTicketFormState = (state) => setItemObject(storageConstants.TICKET_FORM_STATE, state);
const getTicketFormState = () => getItemObject(storageConstants.TICKET_FORM_STATE);

export const storageUtil = {
  setActiveUser,
  setActiveAgent,
  setStatusPermission,
  setCallingStatus,
  setCallingOut,

  getActiveUser,
  getActiveAgent,
  getStatusPermission,
  getCallingStatus,
  getCallingOut,

  clearCallingStatus,

  setTicketFormValue,
  getTicketFormValue,
  setTicketFormState,
  getTicketFormState,
};
