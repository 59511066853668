import React, {Component} from 'react';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Spin} from 'antd';

class Loading extends Component {
  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <strong>Loading</strong>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className='text-center my-5 py-5'>
                  <Spin />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Loading;
