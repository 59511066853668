import {notification} from 'antd';
import axios from 'axios';
import {converter} from 'utils/converter';
import {alertUtil} from 'utils/alert';
import {cookieUtil} from 'utils/cookie';

function run() {
  notification.config({
    placement: 'bottomRight',
  });

  // Add a request interceptor
  axios.interceptors.request.use(
    (config) =>
      // Do something before request is sent
      config,
    (error) =>
      // Do something with request error
      Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) =>
      // Do something with response data
      converter.camelCaseConverter(response.data),
    (error) => {
      if (!error.response) {
        alertUtil.error('Kết nối đến Server thất bại');
      } else {
        if (error.response.status === 401) {
          localStorage.clear();
          cookieUtil.clearAccessToken();
        }

        if (error.response.status < 500) {
          alertUtil.error(error.response.data.message);
        } else {
          alertUtil.error(`Xuất hiện lỗi trên Server`);
        }
      }
      return Promise.reject(error);
    },
  );
}

export const config = {
  run,
};
